import React from 'react';
import PropTypes from 'prop-types';

function CrossDeviceLoginCodeModalBody({
  code,
  translate,
  altTitle,
  altInstruction,
  altDeviceSpecificInstruction
}) {
  let locationInstructions;
  if (altDeviceSpecificInstruction) {
    locationInstructions = (
      <div
        className='cross-device-login-device-specific-instruction-text font-caption-header'
        dangerouslySetInnerHTML={{
          __html: altDeviceSpecificInstruction
        }}
      />
    );
  } else {
    locationInstructions = (
      <div
        className='cross-device-login-instruction-text font-caption-header'
        dangerouslySetInnerHTML={{
          __html: translate('Label.LoginLocation')
        }}
      />
    );
  }

  return (
    <div>
      <div className='cross-device-login-display-code-modal-image' />
      <div className='font-title'>{code}</div>
      <div className='cross-device-login-device-prompt-text font-header-2'>
        {altTitle || translate('Label.DevicePrompt')}
      </div>
      <div className='cross-device-login-instruction-text font-caption-header'>
        {altInstruction || translate('Label.LoginInstructions')}
      </div>
      {locationInstructions}
    </div>
  );
}

CrossDeviceLoginCodeModalBody.defaultProps = {
  altTitle: '',
  altInstruction: '',
  altDeviceSpecificInstruction: ''
};

CrossDeviceLoginCodeModalBody.propTypes = {
  translate: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  altTitle: PropTypes.string,
  altInstruction: PropTypes.string,
  altDeviceSpecificInstruction: PropTypes.string
};

export default CrossDeviceLoginCodeModalBody;
